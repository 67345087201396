nav.side_navbar_admin {
  position: sticky;
  top: 120px;

  i {
    font-size: 28px;
    margin-bottom: 8px;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;

    &.active {
      color: theme-color("primary");
      background-color: rgba($color: theme-color("primary"), $alpha: .1);
      font-weight: 500;
    }

    &:not(.active):hover {
      color: theme-color("primary");
    }
  }
}
