.drag_and_drop_container {
  position: relative;
  height: 300px;
  width: 80%;
  max-width: 100%;
  text-align: center;
  margin: auto;
}

.drag_and_drop_label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: .5rem;
  background-color: rgba($color: theme-color("primary"), $alpha: .05);

  &.drag_active {
    background-color: rgba($color: theme-color("primary"), $alpha: .2);
  }
}

.drag_and_drop_element {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}