// .btn-secondary {
//   color: $white !important;
// }

.action {
  display: flex;
  justify-content: space-between;
  padding-bottom: 28px;
  margin-top: 12px;

  button {
    padding: 8px 36px;
  }
}

@include media-breakpoint-down(sm) {
  .btn_sm_compact {
    padding-left: 24px;
    padding-right: 24px;
  }
}
