@charset "UTF-8";

/* Mono Font for table */
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");

/* Material Design Icon */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.fs_body1 {
  font-size: 1.125rem;
}

.fs_caption {
  font-size: 0.875rem;
}
