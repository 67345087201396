// color
$gray-100: #f8f9fa !default;

// Typography
// $text-muted: #6c757d;

// Components
$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

// Forms
$input-padding-y: 8px;
$input-bg: $gray-100;
$input-border-color: $gray-100;
$input-placeholder-color: #adb5bd;

// Buttons
$btn-padding-y: 8px;
$btn-padding-x: 36px;
