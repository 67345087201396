@charset "UTF-8";

// abstract
@import "./abstract/variables";
// @import "./abstract/mixin";

// 在設定 bootstrap theme 之前，須先 import following 3 submodules
// @import "../../node_modules/bootstrap/scss/functions";
// @import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";

// 設定 theme
$theme-colors: (
  "primary": #008fcb,
  "secondary": #fbc700,
  "third": #00438d,
);
$body-color: #4a4a4a;
// import bootstrap modules
// @import "../../node_modules/bootstrap/scss/bootstrap";

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

// base
// @import "./base/sizing";
// @import "./base/spacing";
@import "./base/typography";

// component
@import "./component/button";
@import "./component/form";
@import "./component/pdf";
@import "./component/table";
@import "./component/drag_and_drop";

// layout
@import "./layout/admin_side_navbar";

// page
@import "./page/sales";

main {
  height: 100vh;
}

a {
  text-decoration: none !important;
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
}
