// a.page-link:hover {
//   color: theme-color("primary") !important;
//   font-weight: 500;
// }

.search_bar {
  position: relative;
}

.input_search {
  width: 100%;
}

.search_icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  height: 42px;
  margin-right: 15px;
  text-align: center;
  color: $gray-600;
  background-color: transparent;
  border: none;
}
