#pdf_container {
  // width: 100%;
  // height: 400px;

  overflow: auto;
  // position: relative;
  // background-clip: content-box;
  background-color: #ffffff;
  // direction: ltr;

  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 16px;
}
