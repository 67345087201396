.table_setting {
  padding-right: 36px;
  padding-left: 36px;

  thead {
    color: $text-muted;

    th {
      font-weight: 400 !important;
    }
  }
}

.table {
  font-family: "Roboto Mono", monospace;
  thead {
    th {
      color: $text-muted !important;
      font-weight: 500;
    }
  }
}
