form {
  label {
    color: $gray-600;
  }
}

// radio button
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: theme-color("primary");
  background-color: theme-color("primary");
}

.custom-control-input:checked ~ .custom-control-label {
  color: theme-color("primary");
  font-weight: 500;
}
